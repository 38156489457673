<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->

  <app-header></app-header>
  <!-- <app-eventos></app-eventos> -->
  <app-simbolos></app-simbolos>
  <app-area-reservada></app-area-reservada>
  <app-quiero-unirme></app-quiero-unirme>
  <app-pie-pagina></app-pie-pagina>
  <router-outlet></router-outlet>
