<div class="container-fluid cdt-header">
    <div class="container ">
        <div class="row no-gutters align-items-center">
            <div class="col-md-2 col-6">
                <img src="assets/imgs/iso.png" />
                <br><br>
            </div>
            <div class="col-md-2 col-6">
                <h3>Caballeros del temple<br><span class="x-small">Nº508</span></h3>
                <br>
            </div>
            <div class="col-md-3"></div>
            <div class="col align-content-right">
                <ul class="nav">
                    <li class="nav-item">
                        <a class="nav-link" href="#">Inicio</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#acercade">Acerca de</a>
                    </li>
                    <!--<li class="nav-item">
                        <a class="nav-link" href="#">Eventos</a>
                    </li>-->
                    <li class="nav-item">
                        <a class="nav-link" href="#simbolos">Símbolos</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#quierounirme">Contacto</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <app-main-section></app-main-section>
</div>