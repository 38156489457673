<div class="container-fluid cdt-area-reservada">
    <div class="container">
        <div class="row">
            <div class="col-md-5">
                <img class="img-fluid" src="assets/imgs/logomas.png">
                <br>
            </div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-3">

                    </div>
                    <div class="col-md-2">
                        <hr>
                    </div>
                    <div class="col-md-7 text-right">
                        <h2>ÁREA RESERVADA</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <form>
                            <div class="form-group">
                                <label for="exampleInputEmail1">Usuario</label>
                                <input type="email" class="form-control" id="exampleInputEmail1"
                                    placeholder="">
                            </div>
                            <div class="form-group">
                                <label for="exampleInputPassword1">Contraseña</label>
                                <input type="password" class="form-control" id="exampleInputPassword1"
                                    placeholder="">
                            </div>
                            
                            <button type="submit" class="btn btn-link">INGRESAR &nbsp;&nbsp;&nbsp;&nbsp;<img src="assets/imgs/puntaflecha.png"></button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <br>
    </div>
</div>