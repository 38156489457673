<div class="container cdt-main-section">
    <div class="row">
        <div class="col-md-10">
            <p class="h3">Valores</p>
            <br>
            <p class="display-3 d-none d-md-block d-lg-block d-xl-block">Libertad, Igualdad Fraternidad</p>
            <p class="display-4 d-block d-sm-block d-md-none d-lg-none d-xl-none">Libertad, Igualdad Fraternidad</p>
            <br>
            <p class="cdt-subtitle">A L.·. G.·. D.·. G.·. A.·. D.·. U.·.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Bajo los auspicios de la Gran Logia de Libres y Aceptados Masones de la Argentina</p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <br>
            <br>
            <a class="btn btn-outline-light btn-lg btn-block">
                Conoce más
            </a>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 cdt-escudo">
            <img src="assets/imgs/escudo.png"/>
        </div>
    </div>
    <div class="row">
        <div class="col-2">
            <hr>
        </div>
        <div class="col">
            <h2 id="acercade" >Nosotros</h2>
        </div>
    </div>
    <div class="row cdt-nosotros">
        <div class="col-md-1"></div>
        <div class="col-md-1"><hr></div>
        <div class="col-md-5">
            <p>La Masonería es una antigüa y discreta institución, formada fundamentalmente por personas libres y de buenas costumbres, con el objetivo de mejorarse mediante el estudio,  el servicio y la fraternidad.
            </p>
            <p>Consideramos a la Ciencia, la Justicia y el Trabajo los ideales más nobles para que nuestros miembros se hagan portadores de valores básicos y esenciales para  la construcción de un futuro sólido y para su realización personal.</p>
        </div>
        <div class="col-md-5">
            <p>Nuestra forma de trabajar representa un sistema didáctico y pedagógico de pensamiento y expresión que ha sido recorrido por personalidades históricas y notables de la historia universal.</p>
            <p>Considerada una institución filantrópica y progresista sus puertas se encuentran abiertas a todos los hombres, sin distinción de razas, religión, ideas políticas, o posición socio económica.</p>
        </div>
    </div>
    <br><br>
</div>
