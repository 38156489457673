<div class="container-fluid cdt-eventos">
    <div class="container">
        <div class="row text-center cdt-main-title">
            <div class="col">
                <h2>Eventos & Novedades</h2>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-1">
                <hr>
            </div>
        </div>
        <div class="row text-center justify-content-center cdt-subtitle">
            <div class="col-md-7">
                <p>
                    Las siguientes son algunas de las actividades programadas y las novedades que tenemos la gratitud de compartir con todos los visitantes del sitio.
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <app-card imgsrc="assets/imgs/event1.jfif"
                cardTitle="Diciembre 2020"
                cardText="El evento de diciembre del 2020 comienza en esa fecha."
                 ></app-card>
                 <br>
                 <p>Tenemos el agrade de invitar a toda la comunidad a la Tenida Blanca en formato de conferencia a cargo del Maestro Masón Marco Tappari, kinesiólogo y especialista en terapias manuales.</p>
                 <br>
            </div>
            <div class="col-md-4">
                <app-card imgsrc="assets/imgs/event2.jpg"
                cardTitle="Noviembre 2020"
                cardText="El evento de Noviembre del 2020 comienza en esa noviembre lógicamente."
                ></app-card>
                <br>
                <p>Tenemos el agrade de invitar a toda la comunidad a la Tenida Blanca en formato de conferencia a cargo del Maestro Masón Marco Tappari, kinesiólogo y especialista en terapias manuales.</p>
                <br>
            </div>
            <div class="col-md-4">
                <app-card imgsrc="assets/imgs/event3.jfif"
                cardTitle="Octubre 2020"
                cardText="El evento de octubre del 2020 comienza antes del de Noviembre."
                ></app-card>
                <br>
                <p>Tenemos el agrade de invitar a toda la comunidad a la Tenida Blanca en formato de conferencia a cargo del Maestro Masón Marco Tappari, kinesiólogo y especialista en terapias manuales.</p>
                <br>
            </div>
        </div>
    </div>
</div>