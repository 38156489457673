import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-area-reservada',
  templateUrl: './area-reservada.component.html',
  styleUrls: ['./area-reservada.component.scss']
})
export class AreaReservadaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
