import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { MainSectionComponent } from './components/main-section/main-section.component';
import { EventosComponent } from './components/eventos/eventos.component';
import { CardComponent } from './components/card/card.component';
import { SimbolosComponent } from './components/simbolos/simbolos.component';
import { RoundedSimpleCardComponent } from './components/rounded-simple-card/rounded-simple-card.component';
import { AreaReservadaComponent } from './components/area-reservada/area-reservada.component';
import { QuieroUnirmeComponent } from './components/quiero-unirme/quiero-unirme.component';
import { PiePaginaComponent } from './components/pie-pagina/pie-pagina.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MainSectionComponent,
    EventosComponent,
    CardComponent,
    SimbolosComponent,
    RoundedSimpleCardComponent,
    AreaReservadaComponent,
    QuieroUnirmeComponent,
    PiePaginaComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
