<div class="container-fluid cdt-quiero-unirme" id="quierounirme">
    <div class="container">
        <div class="row cdt-main-title">
            <div class="col-md-1">
                <hr>
            </div>
            <div class="col-md-11">
                <h2>QUIERO UNIRME</h2>
            </div>
        </div>
        <div class="row cdt-subtitle">
            <div class="col-md-1"></div>
            <div class="col-md-1">
                <hr>
            </div>
            <div class="col-md-7">
                <p>El ingreso requiere de una serie de pasos ordenados que permiten un tránsito progresivo
                    y una experiencia única.</p>

                <p>Si sus intenciones son buenas complete el siguiente formulario y alguien de la Orden se comunicará en
                    el transcurso de las próximas dos semanas.</p>
            </div>
        </div>
        <div class="row cdt-unirse-form">
            <div class="col-md-2"></div>
            <div class="col-md-7">
                <form (submit)="enviarEmail($event)" style="position:relative">
                    <div class="tapita container" *ngIf="sendingEmail">
                        <div class="row justify-content-center">
                            <div class="col align-self-center">
                                <i class="fas fa-circle-notch fa-spin fa-10x"></i>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-10">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Nombre y Apellido</label>
                                        <input name="nombre" class="form-control" placeholder="" title="holis">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Edad</label>
                                        <input name="edad" class="form-control" placeholder="">
                                    </div>

                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Teléfono</label>
                                        <input name="telefono" class="form-control" placeholder="">
                                    </div>

                                </div>
                            </div>
                            <div class="form-group">
                                <label >Ocupación</label>
                                <input name="ocupacion" class="form-control" placeholder="">
                            </div>
                            <div class="form-group">
                                <label >Domicilio</label>
                                <input name="domicilio" class="form-control" placeholder="">
                            </div>
                            <div class="form-group">
                                <label >Motivo por el cual desea unirse</label>
                                <textarea name="motivodeseaunirse" class="form-control" placeholder=""></textarea>
                            </div>
                        </div>
                        <div class="col-md-1"></div>
                        <div class="col-3 cdt-btn-siguiente-form align-self-end">
                            <!-- <button class="btn btn-link" (click)="clickAnterior()" *ngIf="formState > 0"  >
                                <img src="assets/imgs/puntaflechaizq.png">
                                &nbsp;&nbsp;&nbsp;ANTERIOR
                            </button>
                            <button class="btn btn-link" (click)="clickSiguiente()" *ngIf="formState < 2" >
                                SIGUIENTE&nbsp;&nbsp;&nbsp;
                                <img src="assets/imgs/puntaflecha.png">
                            </button> -->
                        </div>
                    </div>
                    <div class="form-check">
                        <label name="hombrelibrecont" class="chkcontainer"> Declaro que me considero un hombre libre y de buenas costumbres.
                            <input name="hombrelibre" type="checkbox">
                            <span class="checkmark"></span>
                        </label>
                        <!--<input type="checkbox" class="form-check-input" id="exampleCheck1">
                        <label class="form-check-label" for="exampleCheck1">Declaro que me considero un hombre libre y de buenas costumbres.</label>-->
                    </div>
                    <br>
                    <br>
                    <button type="submit" class="btn btn-outline-primary">ENVIAR</button>
                    <div name="campoobligatorio" class="tooltip bs-tooltip-bottom" role="tooltip">
                        <div class="arrow"></div>
                        <div class="tooltip-inner">
                            Este campo es obligatorio
                        </div>
                    </div>
                </form>
                <br><br><br>
                <div class="row">
                    <div class="col-md-6">
                        <p><img src="assets/imgs/sobre.png" />&nbsp;&nbsp;contacto@caballerosdeltemple.com.ar</p>
                    </div>
                    <div class="col-md-3">
                        <p><img src="assets/imgs/wsapp.png" />&nbsp;&nbsp;3412275075</p>
                    </div>
                    <div class="col-md-3">
                        <p><img src="assets/imgs/calendario.png" />&nbsp;&nbsp;1<span class="cdt-supers">er</span> y
                            3<span class="cdt-supers">er</span> Lunes</p>
                    </div>
                </div>
            </div>
        </div>
        <br><br><br>
    </div>
</div>