<div class="container-fluid cdt-simbolos" id="simbolos">
    <div class="container">
        <div class="row text-center">
            <div class="col">
                <h2 class="cdt-main-title" >Nuestros Símbolos</h2>
            </div>
        </div>
        <div class="row justify-content-center text-center">
            <div class="col-md-1">
                <hr>
            </div>
        </div>
        <div class="row justify-content-center text-center ">
            <div class="col-md-6">
                <br>
                <p class="cdt-subtitle">Nuestros sìmbolos son parte de la identidad de una logia masónica, representan algunos de nuestros ideales.</p>
            </div>
        </div>
        <div class="row justify-content-center text-center">
            <div class="col-md-3">
                <app-rounded-simple-card imgSrc="assets/imgs/medalla.png"
                cardDescription="Nuestra medalla"
                ></app-rounded-simple-card>
            </div>
            <div class="col-md-3">
                <app-rounded-simple-card imgSrc="assets/imgs/vacio.png"
                cardDescription="Nuestro Escudo"
                ></app-rounded-simple-card>
            </div>
            <div class="col-md-3">
                <app-rounded-simple-card imgSrc="assets/imgs/vacio.png"
                cardDescription="Nuestro Estandarte"
                ></app-rounded-simple-card>
            </div>
        </div>
    </div>
</div>