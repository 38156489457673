import { Component, OnInit } from '@angular/core';
import { trigger, transition, animate, style, state } from '@angular/animations'
import emailjs, { EmailJSResponseStatus } from 'emailjs-com';

@Component({
  selector: 'app-quiero-unirme',
  templateUrl: './quiero-unirme.component.html',
  styleUrls: ['./quiero-unirme.component.scss'],
  animations: [trigger("EnterLeave", [
    state("ltor", style({ transform: 'translateX(-100%)' })),
    state("rtol", style({ transform: 'translateX(100%)' })),
    transition("* => *", [animate("0.5s ease-in-out")])
  ])]
})
export class QuieroUnirmeComponent implements OnInit {

  constructor() { }

  public formState: number = 0;
  public formPartsState = ['', '', ''];
  public sendingEmail:boolean = false;
  ngOnInit(): void {
  }

  clickSiguiente(): Boolean {
    if (this.formState == 2) {
      return false;
    }
    this.formPartsState[this.formState] = 'rtol'
    this.formState += 1;
    this.formPartsState[this.formState] = 'rtol';
    return false;
  }

  clickAnterior(): boolean {
    if (this.formState == 0) {
      return false;
    }
    this.formPartsState[this.formState] = 'ltor';
    this.formState -= 1;
    this.formPartsState[this.formState] = 'ltor';
    return false;
  }

  public enviarEmail(e: Event): boolean {

    e.preventDefault();

    let formulario: HTMLFormElement = e.target as HTMLFormElement;
    

    if (!this.validarFormulario(formulario)) {
      return false;
    }
    this.sendingEmail = true;
    emailjs.sendForm('gmail', 'template_4xmY3vUL', e.target as HTMLFormElement, 'user_yQWReIKlmIa21BNdihHgc')
      .then((result: EmailJSResponseStatus) => {
        alert('Gracias, su solicitud ha sido enviada!');
        this.sendingEmail = false;
        this.limpiarFormulario(formulario);
      }, (error) => {
        console.log(error.text);
      });

    return false;
  }

  private limpiarFormulario(p: HTMLFormElement): void {
    p.nombre.value = "";
    p.edad.value = "";
    p.telefono.value = "";
    p.ocupacion.value = "";
    p.domicilio.value = "";
    p.motivodeseaunirse.value = "";
    p.hombrelibre.checked = false;
  }
  private validarFormulario(p: HTMLFormElement): boolean {

    let tooltip = window.document.getElementsByName("campoobligatorio")[0];
    
    tooltip.style.setProperty("opacity", "0");
    /** validaciones */
    if (!p.nombre.value) {
      this.marcarObligatorio("nombre");
      return false;
    }

    if (!p.edad.value) {
      this.marcarObligatorio("edad");
      return false;
    }

    if (!p.telefono.value) {
      this.marcarObligatorio("telefono");
      return false;
    }
    if (!p.ocupacion.value) {
      this.marcarObligatorio("ocupacion");
      return false;
    }
    if (!p.domicilio.value) {
      this.marcarObligatorio("domicilio");
      return false;
    }
    if (!p.hombrelibre.checked) {
      this.marcarObligatorio("hombrelibrecont");
      return false;
    }


    return true;
  }

  private marcarObligatorio(pControl) {
    let elemento = window.document.getElementsByName(pControl)[0];
    let tooltip = window.document.getElementsByName("campoobligatorio")[0];
    
    tooltip.style.setProperty("opacity", "100");

    (<any>window).Popper.createPopper(elemento, tooltip, {
      placement: 'bottom',
    });
  }


}
